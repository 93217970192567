import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import axios from 'axios';

export default class extends Controller {
  static targets = ['chartContainer'];

  connect() {
    console.log('Rate Chart Controller')
    this.src = this.data.get('src');
    console.log(this.src)
    this.fetchData().then(({ data }) => {
      this.initChart(data);
    });
  }

  async fetchData(data){
    return axios.get(this.src);
  }

  initChart(data) {
    let chartData = this.formatChartData(data);
    let chartLabels = this.formatChartLabels(data);
    let ctx = this.chartContainerTarget;
    // console.log(chartData)
    // console.log(chartLabels)

    new Chart(ctx, {
      
      data: {
        datasets: chartData,
        labels: chartLabels
      },
      options: {
        title: {
          text: 'Price per guests'
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day'
            },
            display: true,
            labelString: 'Date'
          },
          RoomPrice: {
            type: 'linear',
            display: true,
            position: 'left',
            labelString: 'Price per room',
            scalePositionLeft: false,
          },
          RoomCount: {
            type: 'linear',
            position: 'right',
            labelString: 'Rooms count',
            scalePositionLeft: true,
            min: 0,
            max: 50
          }
          
        },
      }
    });
  }

  formatChartLabels(data) {
    return _.map(data, entry => entry.stay_date)
  }

  formatChartData(data) {
    var charData = [];

    console.log(data);
    for (var i = 1; i <= 8; i++) {
      var timeSeries = [];

      _.forEach(data, function(entry){
        timeSeries.push(
          entry[`charge_${i}`]
        )
      })

      if (timeSeries.every(item => item === 0)) { continue }

      charData.push({
        label: `Guests: ${i}`,
        data: timeSeries,
        type: 'line',
        fill: false,
        yAxisID: 'RoomPrice',
        borderColor: this.COLORS[i % this.COLORS.length],
        borderWidth: 1
      });
    }

    var roomsCountTimeSeries = [];

    _.forEach(data, function(entry){
      roomsCountTimeSeries.push(
        entry[`rooms_count`]
      )
    });

    console.log(roomsCountTimeSeries)
    charData.push({
      label: `Rooms count`,
      data: roomsCountTimeSeries,
      type: 'bar',
      yAxisID: 'RoomCount'
    });

    return charData;
  }

  COLORS = [
    '#58595b',
    '#8549ba',
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950'
  ];
}
