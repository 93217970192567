import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['averageRating', 'rating', 'averageRatingImage']

  initialize() {
    this.initCheckedClass();
    this.updateAverageRating();
  }

  initCheckedClass() {
    $('input:checked').each(function() {
      $(this).parent().parent().addClass('checked');
    })
  }

  check(event) {
    var parent = $(event.target).parent().parent();
    var checked = parent.hasClass('checked');
    if(checked) {
      parent.removeClass('checked');
      $(event.target).prop('checked', false);
    }
    else {
      parent.siblings('.checked').removeClass('checked');
      parent.addClass('checked');
    }
  }

  updateRatings(event) {
    this.updateAverageRating();
    this.check(event);
  }

  updateAverageRating() {
    var average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

    let values = this.ratingTargets
      .filter(rating => rating.checked && rating.value > 0)
      .map(rating => parseInt(rating.value));

    let avgRating = (average(values) || 0).toFixed(0);

    var ratingImages = $('.rating-image img').slice(0, 5)
    if(avgRating > 0) {
      $(this.averageRatingImageTarget)
        .attr("src", ratingImages[avgRating - 1].src);
    }
    $(this.averageRatingTarget).text(avgRating);
  }
}
