import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['submitButton', 'accessibilityRadio', 'notesInput'];

  connect() {
    this.setSelectedAccessibilityRadioValue()
    this.setNotesInputValue()
    this.oldValues = this.getValuesArr()
  }

  dataValuesChanged() {
    const changed = (
      JSON.stringify(this.oldValues) !== JSON.stringify(this.getValuesArr())
    )
    if (changed) {
      this.submitButtonTarget.classList.remove('sr-only');
    } else {
      this.submitButtonTarget.classList.add('sr-only');
    }
  }

  getValuesArr() {
    return [
      this.accessibilityRadioValue,
      this.notesInputValue
    ]
  }

  setSelectedAccessibilityRadioValue(value = null) {
    this.accessibilityRadioValue = value || this.accessibilityRadioTargets.find((el) => el.checked == true)?.value;
  }

  setNotesInputValue(value = null) {
    this.notesInputValue = value || this.notesInputTarget?.value
  }

  accessibilityRadioChangeAction(e) {
    const radioValue = e.target.querySelector('input')?.value
    this.setSelectedAccessibilityRadioValue(radioValue)
    this.dataValuesChanged()
  }

  notesInputChangeAction(e) {
    const inputValue = e.target?.value
    this.setNotesInputValue(inputValue)
    this.dataValuesChanged()
  }

}
