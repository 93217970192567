import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if ($(this.element).find('.stars-rating').length == 0) {
      this.addStarsRating()
    };
    $(this.element).on('ratingChange', this.resetRating.bind(this))
  }

  addStarsRating() {
    const rating = Number(this.data.get("rating"));
    const starTotal = 5;
    const starPercentage = (rating / starTotal) * 100;
    const starPercentageRounded = `${(Math.round(starPercentage / 10) * 10)}%`;

    if (rating === 0) { return }
    var wrapper = $('<span>').addClass('stars-rating');

    $('<span>')
      .addClass('stars-rating--inner')
      .css('width', starPercentageRounded)
      .appendTo(wrapper);

    $(this.element).append(wrapper);
  }

  resetRating(_event, data) {
    this.data.set("rating", data.rating);
    $(this.element).find('.stars-rating').remove();
    this.addStarsRating();
  }
}
