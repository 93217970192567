import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['label', 'checkbox'];

  connect() {
    if (this.hasLabelTarget) { this.labelTarget.innerHTML = this.element.querySelector('input[type="checkbox"]')?.checked ? 'Visible' : 'Hidden' }
  }

  updateLabelText(e) {
    this.labelTarget.innerHTML = e.target.checked ? 'Visible' : 'Hidden'
  }
  
  sendForm(e) {
    e.target.closest('form').submit();
  }

  toggleAllCheckboxes(e) {
    const checkboxes = this.checkboxTargets;
    const toCheck = !checkboxes[0].checked;
    
    this.updateButtonText(e.target, toCheck);
    checkboxes.forEach((el) => el.checked = toCheck);
  }

  updateButtonText(button, toCheck) {
    button.innerText = toCheck ? 'Unselect all' : 'Select all';
  }
}
