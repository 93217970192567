import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "minRangeInput",
    "maxRangeInput",
    "rangeInputBar",
    "minPriceInput",
    "maxPriceInput",
  ];

  static values = {
    // Default values for range inputs (min and max range)
    // Default values could be overrided from the html
    minRange: { type: Number, default: 0 },
    maxRange: { type: Number, default: 5000 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 5000 },
    step: { type: Number, default: 100 },
    minRangeBetween: { type: Number, default: 100 }
  }

  initialize() {
    this.ensureCorrectMinRangeBetween();
  }
  connect() {
    this.setDefaults();
    // this.setRangeValuesTo(0, 800); # set custom values from here

    this.setRangeBar();
    this.setRangeButtons();
    this.setRangeInputs();
  }

  changeRangeInput(e) {
    this.minValue = parseInt(this.minPriceInputTarget.value);
    this.maxValue = parseInt(this.maxPriceInputTarget.value);
    this.setRangeBarButtons();
    this.setRangeBar();
  }

  changeRange(e) {

    console.log(this.minPriceInputTarget.value, this.maxPriceInputTarget.value, this.minRangeInputTarget.value, this.maxRangeInputTarget.value);
    this.enforceValidRanges(e);
    this.setRangeBar();
    this.setRangeInputs();
    _.debounce(() => this.triggerChangeEvent(e), 1000)();
  }

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  setRangeValuesTo(min, max) {
    this.minValue = min;
    this.maxValue = max;
  }

  ensureCorrectMinRangeBetween() {
    this.minRangeBetweenValue = Math.max(this.minRangeBetweenValue, this.stepValue);
  }

  setDefaults() {
    if (this.hasMinRangeInputTarget && this.hasMaxRangeInputTarget) {
      this.minRangeInputTarget.min = this.minRangeValue;
      this.minRangeInputTarget.max = this.maxRangeValue;
      this.minRangeInputTarget.step = this.stepValue;

      this.maxRangeInputTarget.min = this.minRangeValue;
      this.maxRangeInputTarget.max = this.maxRangeValue;
      this.maxRangeInputTarget.step = this.stepValue;
    }

    // this.minPriceInputTarget.min = this.minValue;
    // this.minPriceInputTarget.max = this.maxValue;
    // this.maxPriceInputTarget.min = this.minValue;
    // this.maxPriceInputTarget.max = this.maxValue;
    this.minPriceInputTarget.step = this.stepValue;
    this.maxPriceInputTarget.step = this.stepValue;
  }
  setRangeButtons() {
    if (this.hasMinPriceInputTarget && this.hasMaxPriceInputTarget) {
      this.minRangeInputTarget.value = this.minValue;
      this.maxRangeInputTarget.value = this.maxValue;
    }
  }

  enforceValidRanges(event) {
    const min = parseInt(this.minRangeInputTarget.value);
    const max = parseInt(this.maxRangeInputTarget.value);

    this.setRangeValuesTo(min, max);

    const minRangeBetween = this.minRangeBetweenValue;
    const maxRange = parseInt(this.minRangeInputTarget.max);
    const minRange = 0;

    const changedInput = event.target;

    // min changed
    if (changedInput === this.minRangeInputTarget) {
      if (this.minValue < minRange) {
        this.minValue = minRange;
        this.minRangeInputTarget.value = this.minValue;
      }
      if (this.minValue > maxRange - minRangeBetween) {
        this.minValue = maxRange - minRangeBetween;
        this.minRangeInputTarget.value = this.minValue;
      }
      if (this.minValue + minRangeBetween > this.maxValue) {
        this.maxValue = this.minValue + minRangeBetween;
        this.maxRangeInputTarget.value = this.maxValue;
      }
    }

    // max changed
    else if (changedInput === this.maxRangeInputTarget) {
      if (this.maxValue < minRangeBetween) {
        this.maxValue = minRangeBetween;
        this.maxRangeInputTarget.value = this.maxValue;
      }
      if (this.maxValue > maxRange) {
        this.maxValue = maxRange;
        this.maxRangeInputTarget.value = this.maxValue;
      }
      if (this.maxValue - minRangeBetween < this.minValue) {
        this.minValue = this.maxValue - minRangeBetween;
        this.minRangeInputTarget.value = this.minValue;
      }
    }
  }

  setRangeBarButtons() {
    if (this.hasMinRangeInputTarget && this.hasMaxRangeInputTarget) {
      this.minRangeInputTarget.value = this.minValue;
      this.maxRangeInputTarget.value = this.maxValue;
      if (this.maxRangeInputTarget.max < this.maxValue) { this.maxRangeInputTarget.max = this.maxValue };
    }
  }
  setRangeInputs() {
    if (this.hasMinPriceInputTarget && this.hasMaxPriceInputTarget) {
      this.minPriceInputTarget.value = this.minValue;
      this.maxPriceInputTarget.value = this.maxValue;
    }
  }

  setRangeBar() {
    if (this.hasMinRangeInputTarget && this.hasMaxRangeInputTarget) {
      this.maxRange = parseInt(this.maxRangeInputTarget.max);

      let leftPercentage = (this.minValue / this.maxRange) * 100;
      let rightPercentage = 100 - (this.maxValue / this.maxRange) * 100;

      this.rangeInputBarTarget.style.left = leftPercentage + "%";
      this.rangeInputBarTarget.style.right = rightPercentage + "%";
    }
  }

  triggerChangeEvent(e) {
    const target = e.target.dataset.rangeSelectorTarget;

    switch (target) {
      case 'minRangeInput':
        this.minPriceInputTarget.dispatchEvent(new Event('change'));
        break;
      case 'maxRangeInput':
        this.maxPriceInputTarget.dispatchEvent(new Event('change'));
        break;
    }
  }
}
