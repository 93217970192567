import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['addImgLabel', 'imgPreview', 'formField', 'imgPreviewTemplate', 'removePhotoField'];

  showPreview(e) {
    const files = e.target.files;
    if (files) {
      this.addImgLabelTarget.classList.add('sr-only');
      this.imgPreviewTarget.classList.remove('sr-only');

      const fileReader = new FileReader();
      fileReader.onload = event => {
        this.imgPreviewTarget.querySelector('img').setAttribute('src', event.target.result);
      }
      fileReader.readAsDataURL(files[0]);
      
      if(this.hasRemovePhotoFieldTarget) { this.removePhotoFieldTarget.value = false };
    } else {
      this.removePreview();
    }
  }

  removePreview(e) {
    e.preventDefault();
    this.addImgLabelTarget.classList.remove('sr-only');
    this.imgPreviewTarget.classList.add('sr-only');
    if(this.hasRemovePhotoFieldTarget) { this.removePhotoFieldTarget.value = true };
  }

  removeImgContainer(e) {
    e.preventDefault();
    this.imgPreviewTarget.remove();
  }

  appendImgPreview(e) {
    this.removeImgPreviewContainers();

    const files = e.target.files;

    Array.from(files).forEach((image) => {
      const newImgPreview = this.imgPreviewTemplateTarget.cloneNode(true);
      const fileReader = new FileReader();
      fileReader.onload = event => {
        newImgPreview.querySelector('img').setAttribute('src', event.target.result);
      }
      fileReader.readAsDataURL(image);

      this.element.parentNode.insertBefore(newImgPreview, this.element);
    })
  }

  removeImgPreviewContainers() {
    this.element.parentNode.querySelectorAll(":scope > [data-target*='imgPreviewTemplate']").forEach(e => e.remove());
  }
}
