import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['loadingField', 'spinner']
  static values = {
    loadingFor: String
  }

  connect() {
    this.addWrapper();
    if (this.hasLoadingFieldTarget) { this.addSpinners() };
    window.addEventListener('direct-loading', this.render.bind(this));
  }

  disconnect() {
    window.removeEventListener('direct-loading', this.render.bind(this));
  }

  render({ detail: data }) {
    if (this.loadingForValue == data['loading_for']) { this.toggleLoading(data['loading']) };
  }

  toggleLoading(loading) {
    loading ? this.wrapper.classList.toggle('loading') : this.wrapper.classList.remove('loading');
    if (this.hasSpinnerTarget) { this.spinnerTargets.forEach(spinner => spinner.hidden = !loading) };
    if (this.hasLoadingFieldTarget) { this.loadingFieldTargets.forEach(loadingField => loadingField.hidden = loading) };
  }

  addWrapper() {
    const container = this.element;
    const wrapper = Object.assign(document.createElement('div'), { className: 'direct-loading-wrapper'});
    while (container.firstChild) { wrapper.appendChild(container.firstChild) };
    container.appendChild(wrapper);
  }

  addSpinners() {
    this.loadingFieldTargets.forEach(loadingField => {
      loadingField.parentNode.insertBefore(this.spinnerElement, loadingField);
      const spinner = loadingField.previousElementSibling;
      spinner.hidden = true;
    });
  }

  get wrapper() {
    return this.element.querySelector('.direct-loading-wrapper');
  }

  get spinnerElement() {
    const spinnerElement = Object.assign(document.createElement('div'), { className: 'material-symbols-outlined spin-animation loading-animation', innerText: 'progress_activity' });
    spinnerElement.dataset.directLoadingTarget = 'spinner';

    return spinnerElement
  }
}
