import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['extrasContainer', 'wifiPrice', 'batteryPrice', 'batteryPriceContainer', 'totalPrice']

  connect() {
    $(this.element).on(`recalculatePrice`, this.updatePrice.bind(this))
  }

  updatePrice(_e, data) {
    this.wifiPriceFormatted = data.wifi_price
    this.wifiBatteryPriceFormatted = data.battery_price
    this.totalPriceFormatted = data.total_price

    this.updateWifiSection();
    this.updateWifiPriceContainer();
    this.updateBatteryPriceContainer();
    this.updateTotalPrice();
  }

  updateWifiSection() {
    if (this.wifiPriceFormatted) {
      this.extrasContainerTarget.classList.remove('collapse');
    } else {
      this.extrasContainerTarget.classList.add('collapse');
    }
  }

  updateWifiPriceContainer() {
    if (!this.hasWifiPriceTarget) { return };

    if (this.wifiPriceFormatted) {
      this.wifiPriceTarget.innerHTML = this.wifiPriceFormatted;
    } else {
      this.wifiPriceTarget.innerHTML = '';
    }
  }
  
  updateBatteryPriceContainer() {
    if (!this.hasBatteryPriceTarget) { return };

    if (this.wifiBatteryPriceFormatted) {
      if (this.hasBatteryPriceContainerTarget) { this.batteryPriceContainerTarget.hidden = false };
      this.batteryPriceTarget.innerHTML = this.wifiBatteryPriceFormatted;
    } else {
      if (this.hasBatteryPriceContainerTarget) { this.batteryPriceContainerTarget.hidden = true };
      this.batteryPriceTarget.innerHTML = '';
    }
  }

  updateTotalPrice() {
    if (!this.hasTotalPriceTarget) { return };

    this.totalPriceTarget.innerHTML = this.totalPriceFormatted;
  }
}
