import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

export default class extends Controller {
  static targets = [
    'chartContainer',
    'filterForm',
    'startDate',
    'endDate',
    'groupType',
    'currencyIso'
  ];

  connect() {
    this.element.addEventListener("ajax:success", this.render.bind(this));
    this.chart = null;
  }

  render(e) {
    e.preventDefault();

    if(this.chart) { this.chart.destroy() };
    const [data, status, xhr] = e.detail
    this.initChart(data);
  }

  initChart(data) {
    let ctx = this.chartContainerTarget;
    this.chart = new Chart(ctx, this.chartConfig(data));
  }

  chartConfig(data) {
    const currencyIso = this.currencyIsoTarget.value;
    return {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'Profit',
            data: data,
            parsing: {
              yAxisKey: 'profit'
            },
            backgroundColor: '#549EAA',
            tooltip: {

            }
          }
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'date',
        },
        scales: {
          x: {
            type: 'time',
            min: this.startDateTarget.value,
            max: this.endDateTarget.value,
            time: {
              unit: this.groupTypeTarget.value.toLowerCase(),
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            title: {
              display: true,
              text: `Amount in GBP`
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                let date = new Date(context[0].label);
                date = date.toDateString().split(' ').slice(1).join(' ');
                return date
              },
              label: (context) => {
                let label = 'Profit: ';

                if (context.raw.profit !== null) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' }).format(context.raw.profit);
                }
                return label;
              },
              afterLabel: (context) => {
                let label = 'Total revenue: ';

                if (context.raw.total_revenue !== null) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyIso || 'JPY' }).format(context.raw.total_revenue);
                }
                return label;
              }
            }
          }
        }
      }
    };
  }
}
