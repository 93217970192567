import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const title = document.querySelector('meta[property="og:title"]').getAttribute("content");
    const url = document.querySelector('meta[property="og:url"]').getAttribute("content");
    const description = document.querySelector('meta[property="og:description"]').getAttribute("content");
    const image = document.querySelector('meta[property="og:image"]').getAttribute("content");

    const shareLink = this.element;
    const href = shareLink.getAttribute("href")
      .replaceAll("{title}", title)
      .replaceAll("{url}", url)
      .replaceAll("{description}", description)
      .replaceAll("{image}", image);

    if (shareLink.getAttribute("href") !== "#") {
      shareLink.setAttribute("href", href);
    }
  }

  sharePage(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute("href");
    window.open(url, '_blank', 'width=600,height=400');
  }

  printPage(event) {
    event.preventDefault();
    window.print();
  }
}