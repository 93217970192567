import { Controller } from "@hotwired/stimulus";
import { ScrollSpy } from 'bootstrap.native'
import readmoreConfig from '../config/readmoreConfig'

export default class extends Controller {
  static targets = ['content', 'nav']

  connect() {
    this.addNavigation();
    this.setSmoothScroll();
    new ScrollSpy(this.contentTarget, { target: this.navTarget, offset: 30 });
  }

  addNavigation() {
    let self = this
    $(self.navTarget).html('');

    $.each(this.headings, function(index, heading) {
      let i = index + 1

      $(heading).nextUntil('h1, h2, h3').addBack().wrapAll(`<section id="section-${i}">`);

      $(self.navTarget).append(
        `<li class='nav-item'>
          <a class='nav-link py-1 py-md-3' href='#section-${i}'>${heading.innerText}</a>
        </li>`
      );
    })
  }

  setSmoothScroll(){
    $("#sections-nav a").on('click', function(event) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){
        });
      }
    });
  }

  get headings() {
    return this.contentTarget.querySelectorAll("h1, h2, h3");
  }
}
