import { Controller } from "@hotwired/stimulus";
import feather from 'feather-icons';

export default class extends Controller {
  static targets = [
    'hiddenVersions'
  ]

  toggleVersions(event) {
    event.preventDefault();
    let $target = $(event.currentTarget);
    let hidden = $(this.hiddenVersionsTarget).is(':visible');
    $(this.hiddenVersionsTarget).slideToggle();

    if (hidden) {
      $target.text('Show older revisions');
      $target.append("<i class='ms-1' height='19' width='16' data-feather='chevron-down'>");
    }
    else {
      $target.text('Hide older revisions');
      $target.append("<i class='ms-1' height='19' width='16' data-feather='chevron-up'>");
    }
    feather.replace();
  }
}
