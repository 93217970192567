import { Controller } from "@hotwired/stimulus";
import axios  from 'axios'
import feather from 'feather-icons'

export default class extends Controller {
  static targets = [
    'comments',
    'commentsSection',
    'commentsSort',
  ]

  initialize() {
    this.article_slug = this.data.get('slug');
    this.commentSpinner = $(this.commentsSectionTarget).siblings();
  }

  connect() {
    this.fetchComments();
  }

  fetchComments(event) {
    let $target = $(this.commentsSectionTarget)
    $target.hide();
    $target.html('');
    this.commentSpinner.show();
    axios.get(
      this.commentsUrl(),
      {headers: {'X-Requested-With': 'XMLHttpRequest'}}
    ).then(res => {
      this.appendComment(res.data, $target);
      $target.show();
    })
  }

  loadMoreComments(event) {
    event.preventDefault();

    let $button = $(event.currentTarget)
    let ids = $(this.commentsTarget).children('.comment')
      .map(function() {return this.dataset.commentId }).toArray();
    $button.prop('disabled', true)

    axios.get(
      this.commentsUrl(ids),
      {headers: {'X-Requested-With': 'XMLHttpRequest'}}
    ).then(res => {
      this.appendComment(res.data, $(this.commentsTarget));
      $(this.commentsTarget).show();
      let comments_left = $(res.data).data('comments-left') > 0
      if (!comments_left) {
        $button.fadeTo('slow', 0);
        $button.remove();
      } else {
        $button.removeAttr('disabled');
      }
    });
  }

  appendComment(html, target) {
    var comments = $(html).hide();
    target.append(comments);
    comments.show();
    feather.replace();
    this.commentSpinner.hide();
  }

  commentsUrl(ids = []) {
    let value = this.commentsSortTarget.value;

    return `/articles/${this.article_slug}/comments?order=${value}&ids=${ids}`
  }

  showCommentReplyInput(event) {
    event.preventDefault();
    let comment = $(event.currentTarget).closest('.comment')
    $(event.currentTarget).closest('.comment').find('form').first().toggle();
  }

  addComment(event) {
    event.preventDefault();
    let $target = $(event.currentTarget)
    let $targetInput = $target.find('textarea')
    let childComment = $target.find('input[name="comment[parent_comment_id]"]').length
    let $appendTarget = ( childComment ) ? $target.parent() : ($(this.commentsTarget));
    axios.post(`/articles/${this.article_slug}/comments`,
      $target.serialize()
    ).then(res => {
      this.appendComment(res.data, $appendTarget);
      if (childComment) {
        $target.hide();
      }
      $target[0].reset();
      if ($targetInput.parent().hasClass('field_with_errors')) {
        $targetInput.unwrap();
        $targetInput.removeClass('field_with_errors');
        $targetInput.siblings('.field_with_errors').remove();
      }
    }).catch(error => {
      if (!$targetInput.parent().hasClass('field_with_errors')) {
        $targetInput.wrap('<div class="field_with_errors"></div>');
        $targetInput.addClass('field_with_errors');
        $targetInput.after(`
            <div class='field_with_errors mt-n3'>
              ${error.response.data.errors.text[0]}
            </div>
          `);
      }
    }).then(() => {
      $target.find(':submit').removeAttr('disabled');
    });
  }
}
