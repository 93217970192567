import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onPostSuccess(event) {
    let $wrapper = $(event.target).closest('.cost_data'),
        $costEl = $wrapper.find('.cost_data__price'),
        newPrice = $wrapper.find('.cost_data__price-input').val();

    $wrapper.find('.cost_data__updated_at').html('Now!');
    $costEl.html(`${$costEl.data('originalPrice')} ${$costEl.data('currency')} -> ${newPrice} ${$costEl.data('currency')} `);
    $costEl.addClass('text-success');
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    console.log(event)
  }
}