import { Controller } from "@hotwired/stimulus";
import { Dropdown } from 'bootstrap.native'
import select from 'bootstrap-select'
$.fn.selectpicker.Constructor.BootstrapVersion = '4';

export default class extends Controller {
  static targets = [
    'filterSelect',
  ]

  connect() {
    this.filterName = this.filterSelectTarget.dataset.filterName
    this.initializeSelectpicker();
  }

  disconnect() {
    $(this.filterSelectTarget).siblings().remove();
    $(this.filterSelectTarget).unwrap();
  }

  initializeSelectpicker() {

    const $select = $(this.filterSelectTarget).selectpicker(
      {
        selectedTextFormat: "count> 2",
        countSelectedText: function(count) { return `${count} ${this.filterName} selected` },
        liveSearch: true,
        size: 5,
        maxOptions: 5,
        noneSelectedText: `All ${this.filterName}`
      }
    );

    new Dropdown($select.siblings('button')[0]);

    $select.siblings('.dropdown-menu').append(this.actionBox);
  }

  get actionBox() {
    return `
        <div class="bs-actionsbox">
          <div class="btn-group btn-group-sm btn-block">
            <button type="button" class="actions-btn bs-deselect-all btn btn-light">
              Clear
            </button>
            <button type="button" class="btn btn-primary"
            data-action='${this.identifier}#filter'>
              Filter
            </button>
          </div>
        </div>
      `
  }

  filter() {
    let value = $(this.filterSelectTarget).val();
    var url = new URL(window.location.href);
    url.searchParams.set(this.filterName, value);
    window.location.replace(url.toString());
  }
}
