import { Controller } from "@hotwired/stimulus";
import initializeLeafletMap from '../config/leafletConfig'
import * as data from '../geojson/jp-regions.json';
import leaflet from 'leaflet';

export default class extends Controller {
  static targets = [
    'map',
    'mapData',
    'regionName',
    'regionHotelsCount',
    'regionRyokansCount',
    'regionURL',
    'regionDescription',
    'hotelsLink',
    'ryokansLink',
  ]

  initialize() {
    this.regions = JSON.parse(this.mapDataTarget.dataset.mapData);
  }

  connect() {
    this.addMap();
    this.addRegions();
  }

  addRegions() {
    let _this = this;
    let layers = L.geoJSON(data.default, {
      style: function (_feature) {
        return {
          color: '#006770',
          weight: 1,
          fillOpacity: 0.1,
          fillColor: 'white'
        };
      }
    }).on('click', function (data) {
      this.resetStyle();
      _this.regionClick(data.propagatedFrom);
    }).bindTooltip(
      (layer) => {return layer.feature.properties.NAME_1},
      {direction: 'top', sticky: true}
    ).addTo(this.map);

    this._geoJSONLayers = layers;
    this.regionClick(this.selectLayerBySlug('kanto'));
  }

  regionClick(layer) {
    this.highlightRegion(layer);
    this.updateRegionDetails(layer);
  }

  highlightRegion(layer) {
    layer.setStyle(
      {
        fillOpacity: 0.8,
        weight: 3
      }
    );
  }

  updateRegionDetails(layer) {
    let regionSlug = layer.feature.properties.NAME_SLUG;
    let regionData = this.regions[regionSlug];

    $(this.regionNameTarget).text(regionData.name);
    $(this.regionURLTarget).attr('href', regionData.url);
    $(this.regionDescriptionTarget).text(
      regionData.description.substring(0,200)
    );
    $(this.regionHotelsCountTarget).text(regionData.hotels_count);
    $(this.regionRyokansCountTarget).text(regionData.ryokans_count);
    $(this.hotelsLinkTarget).attr(
      'href', this.hotelSearchURL('hotel', regionSlug)
    );
    $(this.ryokansLinkTarget).attr(
      'href', this.hotelSearchURL('ryokan', regionSlug)
    );
  }

  selectLayerBySlug(slug) {
    var selectedLayer;
    this.map.eachLayer(function (layer) {
      if (layer.feature === undefined) { return }
      if (layer.feature.properties.NAME_SLUG == slug) { selectedLayer = layer }
    })

    return selectedLayer
  }

  selectRegion(event) {
    event.preventDefault();
    let slug  = event.currentTarget.dataset.slug,
        layer = this.selectLayerBySlug(slug);

    this._geoJSONLayers.resetStyle();
    this.regionClick(layer)
  }

  hotelSearchURL(category, region) {
    return `
      /hotels?search_form%5Bcategories%5D%5B%5D=${category}&search_form%5Bregion%5D=${region}
    `
  }

  addMap() {
    this.map = initializeLeafletMap(
      {
        tilerKey: this.data.get('tiler'),
        mapTargetId: this.mapTarget.id,
        viewCoordinates: [38.5, 139],
        zoom: 5
      }
    );
  }

  toggleFullscreen(e) {
    e.preventDefault();

    this.map.toggleFullscreen();
  }
}
