import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['mapContainer', 'listItem', 'mapCaption']

  initialize() {
    this.activeRegion = this.element.dataset.activeRegion;
  }

  connect() {
    let element = this.listItemTargets.find(el => el.dataset.name == this.activeRegion)
    this.activateListElement(element);
  }

  updateHeader(e) {
    const listElement = e.target.closest("[data-destinations-map-target='listItem']");
    this.mapContainerTarget.src = listElement.dataset.imageUrl;
    this.deactivateListElement();
    this.activateListElement(listElement);
    this.activeRegion = listElement.dataset.name;
  }

  deactivateListElement() {
    this.listItemTargets.find(el => el.dataset.activated == 'true').dataset.activated = false;
  }

  activateListElement(target) {
    target.dataset.activated = true;
    this.mapCaptionTarget.innerText = target.innerText.split('\n')[0];
  }
}
