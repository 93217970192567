import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['modal', 'image', 'imageCaption', 'thumbnails', 'thumbnailsContainer']

  initialize() {
    this.modal = this.modalTarget
  }

  connect() {
  }

  openModal(event) {
    event.preventDefault();

    this.modal.showModal();
  }

  closeModal() {
    this.modal.close();
  }

  openNextImage(event) {
    event.preventDefault();
  
    let currentImage = this.imageTarget.src;
    let thumbnails = this.thumbnailsTargets;
    let currentIndex = Array.from(thumbnails).findIndex(thumbnail => thumbnail.src === currentImage);

    if (currentIndex === -1) {
      return; // Current image not found in thumbnails
    }

    let nextIndex = (currentIndex + 1) % thumbnails.length;
    let nextImageSrc = thumbnails[nextIndex].dataset.src;
    let nextImageCaption = thumbnails[nextIndex].dataset.caption;
 
    this.imageTarget.src = nextImageSrc;
    this.imageCaptionTarget.innerText = nextImageCaption;
  }

  openPreviousImage(event) {
    event.preventDefault();
  
    let currentImage = this.imageTarget.src;
    let thumbnails = this.thumbnailsTargets;
    let currentIndex = Array.from(thumbnails).findIndex(thumbnail => thumbnail.src === currentImage);

    if (currentIndex === -1) {
      return; // Current image not found in thumbnails
    }

    let previousIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length;
    let previousImageSrc = thumbnails[previousIndex].dataset.src;
    let previousImageCaption = thumbnails[previousIndex].dataset.caption;

    this.imageTarget.src = previousImageSrc;
    this.imageCaptionTarget.innerText = previousImageCaption;
  }

  openImage(event) {
    event.preventDefault();

    this.imageTarget.src = event.target.dataset.src;
  }

  scrollThumbnailsLeft(event) {
    event.preventDefault();

    this.thumbnailsContainerTarget.scrollLeft -= 158;
  }

  scrollThumbnailsRight(event) {
    event.preventDefault();

    this.thumbnailsContainerTarget.scrollLeft += 158;
  }
}
