import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    initialize() {
      this.baseHeight = this.element.querySelector('textarea').style.height;
    }

    resize(event){
      event.target.style.height = this.baseHeight;
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
}
