import { Controller } from "@hotwired/stimulus";
import initializeLeafletMap from '../config/leafletConfig'
import markerShadowImage from 'leaflet/dist/images/marker-shadow.png';
import markerIconImage from 'leaflet/dist/images/marker-icon.png';

export default class extends Controller {
  static targets = [
    'mapContainer',
    'pin'
  ]

  initialize() {
    this.zoom = parseInt(this.mapContainerTarget.dataset.zoom) || 13;
    this.markers = [];
  }

  connect() {
    this.addMap();
    if (this.pins.length) { this.addMarkers(); }
  }

  addMap() {
    this.map = initializeLeafletMap(
      {
        tilerKey: this.data.get('tilerApiKey'),
        mapTargetId: this.mapContainerTarget.id,
        viewCoordinates: this.viewCoordinates,
        zoom: this.zoom
      }
    );
  }

  addMarkers() {
    let icon = { icon: L.icon(this.iconConfig) };
    var self = this;

    this.pins.forEach(function (pin, _index) {
      let marker;

      if (pin.type == 'circle'){ 
        marker =
          L.circleMarker([pin.latitude, pin.longitude], {color: '#3388ff', radius: 5}).addTo(this.map)
            .bindTooltip(pin.name);
      } else {
        marker =
          L.marker([pin.latitude, pin.longitude], icon).addTo(this.map)
          .bindTooltip(pin.name);
      }
      self.markers = [...self.markers, marker];
    }, this);
  }

  get pins() {
    var pins_data = [];

    $(this.element).find('.map-pin').each(function(){
      pins_data = [...pins_data, $(this).data()]
    });

    return pins_data;
  }

  get averageLatitude() {
    if (this.pins.length) {
      return this.pins.reduce((total, next) => total + next.latitude, 0) / this.pins.length;
    } else {
      return 35.652832;
    }
  }

  get averageLongitude() {
    if (this.pins.length) {
      return this.pins.reduce((total, next) => total + next.longitude, 0) / this.pins.length;
    } else {
      return 139.839478;
    }
  }

  get viewCoordinates() {
    return [this.averageLatitude, this.averageLongitude];
  }

  get iconConfig() {
    return {
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: markerIconImage,
      shadowUrl: markerShadowImage
    }
  }
}
