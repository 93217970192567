import { Controller } from "@hotwired/stimulus";
import feather from "feather-icons"
import changeInputCount from '../modules/changeInputCount'

export default class extends Controller {
  static targets = [
    'roomsField',
    'guestsField',
    'roomsInput',
    'guestsAdultsInput',
    'guestsChildrenInput'
  ]

  connect() {
    this.updateGuestsField();
    this.updateRoomsField();
  }

  updateGuestsField() {
    var adultsCount = parseInt(this.guestsAdultsInputTarget.value) || 0;
    var childrenCount = parseInt(this.guestsChildrenInputTarget.value) || 0;
    var adultsWord = adultsCount === 1 ? 'adult' : 'adults';
    var childrenWord = childrenCount === 1 ? 'child' : 'children';
    var text = [adultsCount, adultsWord, childrenCount, childrenWord].join(' ')

    if (adultsCount >= 1 && adultsCount <= 9 && childrenCount >= 0 && childrenCount <= 9) {
      $(this.guestsFieldTarget).html(text);
    }
  }

  updateRoomsField() {
    var roomsCount = parseInt(this.roomsInputTarget.value) || 0;

    if (roomsCount >= 1 && roomsCount <= 5) {
      $(this.roomsFieldTarget).html(roomsCount);
    }
  }

  changeInputCount(event) {
    changeInputCount(event)
  }

  submit(event) {
    event.preventDefault();
    $(event.target).parents('form').trigger('submit');
  }
}
