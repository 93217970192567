import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'totalPrice',
    'nextStepButton',
    'submitButton',
    'extrasSection',
    'extrasPrice',
    'payButton'
  ]

  connect() {
    $(this.element).on(`recalculatePrice`, this.updatePrice.bind(this))
    document.addEventListener('scroll', this.controlSumbitButtonVisibility.bind(this));
  }

  updatePrice(_e, data) {
    const extrasPrice = data?.extras_price;
    const totalPrice = data.total_price;

    this.updateExtrasSection(extrasPrice);
    this.updateTotalPrice(totalPrice);
  }

  updateExtrasSection(value) {
    if (value) {
      this.extrasSectionTarget.classList.remove('d-none');
      this.extrasPriceTarget.innerHTML = value;
    } else {
      this.extrasSectionTarget.classList.add('d-none');
    }
  }

  updateTotalPrice(value) {
    this.totalPriceTarget.innerHTML = value
  }

  updateButton() {
    this.nextStepButtonTarget.classList.add('collapse');
    this.submitButtonTarget.classList.remove('collapse');
    this.submitButtonTarget.value = `${this.submitButtonTarget.value} ${this.totalPriceTarget.innerText}`;
  }

  controlSumbitButtonVisibility() {
    if(!document.getElementById('dropin')) { return }
    
    const paymentElementY = document.getElementById('dropin').getBoundingClientRect().top
    if (paymentElementY < window.scrollY) {
      this.payButtonTarget.classList.add('visually-hidden');
    } else {
      this.payButtonTarget.classList.remove('visually-hidden');
    }
  }
}
