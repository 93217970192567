import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.setOptionsAvailability();
  }

  setOptionsAvailability() {
    const allOptions = this.element.querySelectorAll('[type=checkbox]');
    const unselected = Array.from(allOptions).filter((o) => !o.checked);
    const limitReached = allOptions.length - unselected.length >= this.element.dataset.checkedLimit;
    
    unselected.forEach((o) => o.disabled = limitReached);
  }
}
