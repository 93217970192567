import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr"
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'

export default class extends Controller {
  static targets = [
    'dateFrom',
    'dateTo'
  ]

  initialize() {
    // if When search dates are incomplete or not set,
    // then set default range for 7 days from tomorrow 
    this.defaultSearchFor_x_days = 7;
  }
  connect() {
    this.fromValue = this.dateFromTarget.value;
    this.toValue = this.dateToTarget.value;
    this.initDatepicker();
  }

  initDatepicker() {  
    let flatpickrOptions = {
        disableMobile: 'true',
        minDate: new Date().fp_incr(1),
        maxDate: new Date().fp_incr(300),
        allowInput: false,
        altInput: true,
        altFormat: "d M, Y",
        "plugins": [new rangePlugin({ input: this.dateToTarget })],
        onClose: (selectedDates, _, instance) => {
          if (!selectedDates[0] || !selectedDates[1]) return;

          if (selectedDates[0].getTime() === selectedDates[1].getTime()) {
            selectedDates[1] = selectedDates[0].fp_incr(1);
            instance.setDate(selectedDates, true);
          }
        }
    }

    flatpickr(this.dateFromTarget, flatpickrOptions);
  }
}
