import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['photoInput', 'userPhotoPreview']

  clearPhotoInput() {
    this.photoInputTarget.value = '';
    this.userPhotoPreviewTarget.src = '';
  }

  renderPhotoPreview() {
    let input = this.photoInputTarget;

    if (input.files && input.files[0]) {
      this.userPhotoPreviewTarget.src = URL.createObjectURL(input.files[0])
    }
  }
}
