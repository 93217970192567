import feather from 'feather-icons'
import readmore from '../vendor/readmore'

export default function (options) {
  $(`${options.className}`).readmore('.readmore', {
    speed: 300,
    collapsedHeight: options.collapsedHeight,
    moreLink: link(options.linkText, 'down'),
    lessLink: link(options.linkText, 'up'),
    afterReplace: function () { feather.replace() }
  });

}

function link(text, direction) {
  return `
  <a class="small text-decoration-underline mt-2" href="#">
    ${text}
    <i data-feather="chevron-${direction}" width="16"></i>
  </a>
    `
}
