import { Controller } from "@hotwired/stimulus";
import axios from 'axios'

export default class extends Controller {

  initialize() {
    this.clientKey        = this.data.get('client-key');
    this.bookingSessionId = this.data.get('booking-session-id');
    this.amount           = this.data.get('amount');
    this.fullName         = this.data.get('full-name');
    this.currency         = this.data.get('currency');
    this.adyenEnv         = this.data.get('aenv');
    this.countryIso       = this.data.get('iso');
    this.locale           = this.data.get('lang');
  }

  connect() {
    let self = this;

    this.getPaymentMethods().then(response => {
      if(response.data.action === 'payment_methods') {
        self.initializeAdyen(response.data.methods).then(self.bindForm.bind(self));
      } else {
        console.log('Error: ', response.data.error_code)
      }
    })
  }

  async initializeAdyen(paymentMethods) {
    const configuration = {
      locale: this.locale,
      environment: this.adyenEnv,
      clientKey: this.clientKey,
      paymentMethodsResponse: paymentMethods
    };

    return await AdyenCheckout(configuration);
  }

  getPaymentMethods() {
    return axios.get(`/booking_sessions/${this.bookingSessionId}/payment_methods`)
  }

  bindForm(checkout) {
    let self = this;

    this.dropin = checkout
      .create('dropin', {
        amount: { value: self.amount, currency: self.currency },
        openFirstPaymentMethod: true,
        openFirstStoredPaymentMethod: true,
        paymentMethodsConfiguration: {
          card: {
            holderName: self.fullName,
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            name: 'Credit or debit card'
          }
        },
        onSubmit: (state, dropin) => {
          self.makePayment(state.data)
            .then(response => self.handleResponse(response, dropin))
            .catch(error => self.displayCustomErrorMessage(`Critical error: ${error}`));
        },
        onAdditionalDetails: (state, dropin) => {
          self.makeDetailsCall(state)
            .then(response => self.handleResponse(response, dropin))
            .catch(error =>  self.displayCustomErrorMessage(`Critical error: ${error}`));
        },
        onReady: () => {
          $('#dropin-placeholder').remove();
        }
      })
      .mount('#dropin');
  }

  handleResponse(response, dropin) {
    console.log(response);

    if (response.data.resultCode == 'Authorised') {
      dropin.setStatus('success', { message: 'Payment successful!' });
      this.redirectToSuccessPage();
    } else if (response.data.action) {
      dropin.handleAction(response.data.action);
    } else {
      let errorMsg = response.data.refusalReason || response.data.message;
      this.displayCustomErrorMessage(errorMsg);
    }
  }

  makePayment(state) {
    console.log(state)
    return axios.post(`/booking_sessions/${ this.bookingSessionId}/payments`, state)
  }

  makeDetailsCall(state) {
    console.log('onAdditionalDetails', state);
    return axios.post(`/booking_sessions/${this.bookingSessionId}/payment_details`, state)
  }

  redirectToSuccessPage() {
    var success_path = `/reservations/${this.bookingSessionId}`;
    setTimeout(function(){
      window.location.assign(success_path);
    }, 3000)
  }

  reloadForm() {
    window.location.reload();
  }

  displayCustomErrorMessage(msg) {
    var message = msg || "An unknown error occurred";

    this.dropin.setStatus('error', { message: message });
    setTimeout(this.reloadForm, 3000);
  }
}
