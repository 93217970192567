import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';
import axios from 'axios';

export default class extends Controller {
    connect() {
        this.allowDeselect = this.element.dataset['allowDeselect'] == undefined ? true : (this.element.dataset['allowDeselect'] == 'true');
        this.fetchUrl = this.element.dataset['fetchUrl'];
        this.selectedOptionValue = this.element.querySelector('[selected="selected"]')?.value;
        this.defaultOptions = [...this.element.options].map((option) => {
            if (option.value == '') {
                return { placeholder: true, text: option.innerText, value: '' }
            } else {
                return { value: option.value, text: option.innerText } 
            }
        });
        this.initializeSlimSelect();
    }

    initializeSlimSelect() {
        const that = this;

        this.select= new SlimSelect({
            select: this.element,
            data: this.defaultOptions,
            settings: {
                allowDeselect: this.allowDeselect,
            },
            events: {
                search: (search, currentData) => {
                    return new Promise((resolve) => {
                        clearTimeout(this.searchTimeout);
                        
                        this.searchTimeout = setTimeout(() => {
                            if (search.length < 3 || !this.fetchUrl) {
                                resolve(that.defaultOptions.filter(option => option.text.toLowerCase().includes(search.toLowerCase())));
                            } else {
                                axios.get(`${that.fetchUrl}?query=${search}`).then(response => {
                                    const options = response.data
                                        .filter(data => !currentData.some(optionData => optionData.value === data.value))
                                        .map(data => ({
                                            text: data.text,
                                            value: data.value
                                        }));
                                    resolve(options);
                                }).catch(error => {
                                    console.error(error);
                                    resolve(that.defaultOptions);
                                });
                            }
                        }, 300);
                    });
                }
            }
        });
        if (this.selectedOptionValue) {
            this.select.setSelected(this.selectedOptionValue);
        };        
    }

    disconnect() {
        this.select.destroy();
    }
}
