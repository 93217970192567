import { Controller } from "@hotwired/stimulus";
import axios from 'axios'
import toastr from 'toastr'


export default class extends Controller {
  connect() {
    this.state = (this.element.dataset['state']?.toLowerCase() === 'true')
    this.recordId = parseInt(this.element.dataset['recordId']) || null;
    this.trueStateText = this.element.dataset['trueStateText'];
    this.falseStateText = this.element.dataset['falseStateText'];
    this.url = this.element.dataset['url'];
    this.updateElementUx(this.state);
  }

  updateElementUx(state) {
    this.element.querySelector('span.material-symbols-outlined')?.classList?.toggle('material-symbols-outlined--0-75x--fill', state)
    this.element.querySelector('span.material-symbols-outlined')?.classList?.toggle('icon-filled', state)
    this.element.querySelector('svg.feather')?.classList?.toggle('icon-filled', state);
    const textElement = this.element.querySelector('span.text-decoration-underline');
   
    if(textElement && this.trueStateText && this.falseStateText) {
      textElement.innerHTML = (state ? this.trueStateText :  this.falseStateText);
    } 
  }

  toggleState(event) {
    event.preventDefault();

    axios.post(this.url, { hotel_id: this.recordId})
      .then(response => {
        this.state = !this.state;
        this.updateElementUx(this.state);
      }).catch((error) => {
        toastr.error(error.message);
      })
  }
}
