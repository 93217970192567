import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
  static targets = [
    "verificationBox",
  ]

  verify(event) {
    event.preventDefault();
    let self = this;
    const url = event.target.dataset.url
    axios.get(url)
      .then((res) => {
        self.showVerificationResults(res.data)
      });
  }

  showVerificationResults(jsonData) {
    let classNameStr;
    switch (jsonData.status) {
      case 'success': 
        classNameStr = "alert alert-success";
        break;
      case 'error':
        classNameStr = "alert alert-danger";
        break;
      default:
        classNameStr = "alert alert-info";
        break;
      }
          
    this.verificationBoxTarget.querySelector('pre').className = classNameStr;
    this.verificationBoxTarget.querySelector('pre code').innerHTML = jsonData.message;
    this.verificationBoxTarget.classList.toggle('d-none', false)
  }
}
