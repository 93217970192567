import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['text', 'counter'];

  connect() {
    this.maxLength = this.element.dataset.maxLength;
    this.initializeCounter();
  }

  initializeCounter() {
    this.counterTarget.innerText = `${this.textTarget.value.length}/${this.maxLength}`;
  }

  updateCounter() {
    const textLength = this.textTarget.value.length;
    
    if (textLength > this.maxLength) {
        this.textTarget.value = this.textTarget.value.substring(0, this.maxLength);
    }
    this.counterTarget.innerText = `${this.textTarget.value.length}/${this.maxLength}`;
  }
}

