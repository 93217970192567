import leaflet from 'leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

export default function (options) {
  const tilesURL =
    `https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${options['tilerKey']}`;


  const tileLayerConfig = {
    tileSize: 512,
    zoomOffset: -1,
    minZoom: 1,
    attribution: `
    <a href="https://www.maptiler.com/copyright/" target="_blank">
      © MapTiler
    </a>
    <a href="https://www.openstreetmap.org/copyright" target="_blank">
      © OpenStreetMap contributors
    </a>
  `
  }

  const map = L.map(options['mapTargetId'], { fullscreenControl: true })
    .setView(options['viewCoordinates'], options['zoom']);

  L.tileLayer(tilesURL, tileLayerConfig).addTo(map);

  return map;
}
