import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.setAttribute('data-action', 'dragover->sortable#dragOver');

    const draggableElements = this.getDraggableElements();
    draggableElements.forEach((el) => {
      el.setAttribute('draggable', true);
      el.setAttribute('data-action', 'dragstart->sortable#dragStart dragend->sortable#dragEnd');
    });

    const links = this.element.querySelectorAll('.draggable a');
    links.forEach((el) => el.setAttribute('draggable', false));
  }

  dragStart(e) {
    e.target.classList.add('dragging');
    const draggablePreview = this.getDragImage();
    if(draggablePreview) { e.dataTransfer.setDragImage(draggablePreview, e.offsetX, e.offsetY) }
  }

  dragEnd(e) {
    e.target.classList.remove('dragging');
  }

  dragOver(e) {
    e.preventDefault();
    const draggingElement = this.element.querySelector('.dragging');
    const draggableElements = this.getDraggableElements();
    const nextSibling = Array.from(draggableElements).find((el) => el.getBoundingClientRect().y > e.clientY);
    this.element.insertBefore(draggingElement, nextSibling);
  }

  getDraggableElements() {
    return this.element.querySelectorAll('.draggable');
  }

  getDragImage() {
    return this.element.querySelector('.dragging .draggable-preview');
  }
}
