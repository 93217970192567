import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dropdown'];

  connect() {
    document.addEventListener('click', this.hide.bind(this));
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownTarget.classList.toggle('show');
  }

  blockClose(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  hide() {
    this.dropdownTarget.classList.remove('show');
  }

  disconnect() {
    document.removeEventListener('click', this.hide.bind(this));
  }
}
