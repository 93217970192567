import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr"

export default class extends Controller {

  connect() {
    this.initDatepicker();
  }

  initDatepicker() {
    let flatpickrOptions = {
      dateFormat: 'd M Y',
      maxDate: new Date().fp_incr(1),
      allowInput: true,
    }

    // $(this.element).flatpickr(flatpickrOptions);
    $(this.element).flatpickr();
  }
}
