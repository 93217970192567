import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['password', 'icon', 'feedback'];

  toggle() {
    const isPasswordType = this.passwordTarget.type === 'password';
    this.passwordTarget.type = isPasswordType ? 'text' : 'password';
    this.iconTarget.textContent = isPasswordType ? 'visibility' : 'visibility_off';
  }

  validate() {
    const password = this.passwordTarget.value;
    const feedback = this.feedbackTarget;

    if (password.length === 0) {
      feedback.textContent = '';
      feedback.classList.remove('weak-password', 'strong-password');
    } else {
      const isStrong = password.length >= 8 && /(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/.test(password);
      feedback.textContent = isStrong ? 'Strong password' : 'Weak password';
      feedback.classList.toggle('strong-password', isStrong);
      feedback.classList.toggle('weak-password', !isStrong);
    }
  }
}
