// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require.context('../images', true)

import "controllers"
import setupCSRFToken from '../config/setupCSRFToken'
import turbolinksPrepareBeforeCache from '../config/turbolinksPrepareBeforeCache'
import feather from "feather-icons"
import toastr from 'toastr'
import "bootstrap";

var _ = require('lodash');

window.toastr = toastr
var container = document.querySelectorAll('[data-function="turbolinks-autoload"]')[0]
document.addEventListener('turbolinks:load', function(){
  BSN.initCallback(container);
  setupCSRFToken();
  feather.replace();
}, false);

document.addEventListener("turbolinks:before-cache", function() {
  turbolinksPrepareBeforeCache();
});

