export default function (event) {
  event.preventDefault();

  var target = $(event.target).siblings('input');
  var currentValue = parseInt($(target).val()) || 0;
  var increment = parseInt(event.target.dataset.value);
  var newValue = currentValue + increment;
  var min = target[0].min;
  var max = target[0].max;

  if(newValue >= min && newValue <= max) {
    target.val(newValue);
  }
}
