import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['bestTimeToVisitsContainer', 'cloningTemplate', 'addButton'];

  connect() {
    this.updateAddButtonState();
  }

  addClonedTemplate(e) {
    e.preventDefault();
    const clonedTemplate = this.cloningTemplateTarget.content.cloneNode(true);
    this.bestTimeToVisitsContainerTarget.appendChild(clonedTemplate);
    this.updateAddButtonState();
  }

  removeFormFieldsContainer(e) {
    e.preventDefault();
    e.target.closest(".d-flex")?.remove();
    this.updateAddButtonState();
  }

  updateAddButtonState() {
    const formGroupCount = this.bestTimeToVisitsContainerTarget.querySelectorAll('.form-group.d-flex').length;
    this.addButtonTarget.disabled = formGroupCount >= 2
  }
}
