import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setValueOfSelector();
  }

  redirectToSortLink(event) {
    const redirectPath = event.target.selectedOptions[0].dataset.url;
    if(redirectPath) { window.location.href = redirectPath }
  }

  findSortingOption() {
    return Array.from(this.element.options).find((option) => {
      return window.location.search.indexOf(option.value) != -1
    })
  }

  setValueOfSelector() {
    const sortingOption = this.findSortingOption();
    if(sortingOption) { this.element.value = sortingOption.value };
  }
}
