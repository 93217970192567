import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['image', 'imageCaption']

  switchImage(event) {
    this.imageTarget.src = event.target.dataset.newSrc
    this.imageCaptionTarget.innerText = event.target.dataset.newCaption
  }
}
