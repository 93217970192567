import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    utcDate: String
  }

  static targets = ['localUserTime']

  connect() {
    if (this.hasLocalUserTimeTarget) {
      this.setUserTimezoneDate(this.localUserTimeTarget);
    } else {
      this.setUserTimezoneDate(this.element);
    }
  }

  setUserTimezoneDate(element) {
    if (this.hasUtcDateValue && this.isValidDate(this.utcDateValue)) {
      const localTime = this.formatDateToUserTimezone(this.utcDateValue);

      element.innerText = localTime;
    }
  }

  formatDateToUserTimezone(dateString) {
    const date = new Date(dateString);
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime = new Intl.DateTimeFormat('en-GB', {
      timeZone: localTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(date);
    
    return localTime;
  }

  isValidDate(date) {
    return !isNaN(Date.parse(date));
  }
}
